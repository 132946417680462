var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("FormulateInput", {
        attrs: {
          id: _vm.id + "_pId_" + _vm.product.id,
          type: "osk-number-with-buttons",
          name: _vm.id + "_pId_" + _vm.product.id,
        },
        on: {
          oskInput: _vm.onOskInput,
          leftClick: _vm.onMinusClick,
          rightClick: _vm.onPlusClick,
        },
        model: {
          value: _vm.getItemQuantity,
          callback: function ($$v) {
            _vm.getItemQuantity = $$v
          },
          expression: "getItemQuantity",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "p-2 d-flex flex-column justify-content-center align-items-center",
    },
    [
      _c(
        "b-row",
        { staticClass: "w-100", staticStyle: { position: "relative" } },
        [
          _c(
            "b-col",
            { attrs: { md: "12" } },
            [
              _c(
                "b-card",
                { staticClass: "w-100 h-auto", attrs: { title: "Resumo" } },
                [
                  _c(
                    "div",
                    [
                      _c("e-pay-box-sale-item-table", {
                        staticClass: "bordered",
                        attrs: {
                          "table-items": _vm.getItems,
                          "show-img": "",
                          "item-read-only": "",
                          "prices-to-right": "",
                          "show-number": false,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "mt-1 d-flex justify-content-end " },
                    [
                      _c("p", { staticClass: "h3" }, [
                        _vm._v(
                          " Total: " +
                            _vm._s(_vm._f("currency")(_vm.getTotalSale)) +
                            " "
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "payment-options-container", attrs: { md: "12" } },
            [
              _c(
                "b-card",
                {
                  staticClass: "mb-1",
                  attrs: { title: _vm.$t("Escolha a forma de pagamento") },
                },
                [
                  _c(
                    "b-row",
                    { staticClass: "mb-1", staticStyle: { "row-gap": "10px" } },
                    _vm._l(_vm.paymentMethodsInAtm, function (item) {
                      return _c(
                        "b-col",
                        { key: item.id, attrs: { md: "4" } },
                        [
                          _c("e-button-card", {
                            attrs: {
                              id: "atm_payment-method_btn-" + item.id,
                              "icon-component": "BIcon",
                              icon: _vm.getIconByPaymentMethod[item.method],
                              "text-title": item.name,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.onShowModalValue(item)
                              },
                            },
                          }),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _vm.hasCanceledTefPixPayment
                        ? _c(
                            "b-col",
                            { attrs: { md: "12" } },
                            [
                              _c(
                                "b-alert",
                                {
                                  staticClass: "p-2",
                                  attrs: { show: "", variant: "danger" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "PAY_BOX_SALE.TEF.ATM.INSTRUCTION_TEF_CANCEL_PIX"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "b-col",
                        { attrs: { md: "12" } },
                        _vm._l(
                          _vm.paymentForm.receipts,
                          function (receipt, index) {
                            return _c("method-line", {
                              key: index,
                              staticClass: "rounded",
                              attrs: {
                                id: "atm_payment-method_item-" + index,
                                receipt: receipt,
                                value: receipt.value,
                                "is-cancelled": receipt.canceled,
                                "show-btn-canceled": _vm.showCancelBtn(receipt),
                              },
                              on: {
                                "click-delete": function ($event) {
                                  return _vm.onDeleteMethodPayment(index)
                                },
                                "click-print": function ($event) {
                                  return _vm.stPrintReceipt({ index: index })
                                },
                              },
                            })
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("modal-value", {
        ref: "modalValue",
        attrs: {
          "sale-uuid": _vm.currentSaleUuid,
          "balance-value": _vm.paymentForm.balance,
          "btn-confirm-busy": _vm.waitPeripheral,
          "is-atm": "",
        },
        on: { "click-confirm": _vm.onStartFinishFlow },
      }),
      _c("modal-atm-customer", {
        ref: "modalAtmCustomerInPayment",
        attrs: { id: "payment" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="h-100 d-flex flex-column">
    <div
      class="w-100"
      style="height: 90px"
    >
      <div
        class="px-2 py-1 h-100 d-flex justify-content-between align-items-center"
        style="gap: 20px;"
      >
        <template v-if="getAtmScreen === atmScreenEnum.CATALOG">
          <e-button
            variant="danger"
            class="w-100 h-100"
            :text="$t('Cancelar compra')"
            size="lg"
            @click="() => onCancel({})"
          />

          <e-button
            variant="primary"
            class="w-100 h-100"
            :text="$t('Carrinho')"
            size="lg"
            @click="() => onShowCart()"
          >
            <template #right-content>
              <b-badge
                v-if="getCartItemsQuantity > 0"
                variant="danger"
              >
                {{ getCartItemsQuantity }}
              </b-badge>
            </template>
          </e-button>
        </template>

        <template v-if="getAtmScreen === atmScreenEnum.PAYMENT">
          <e-button
            v-if="showReturn"
            :busy="isFinishingSale"
            variant="secondary"
            class="w-100 h-100"
            :text="$t('Voltar')"
            size="lg"
            @click="() => onBack()"
          />

          <e-button
            v-if="showCancel"
            variant="danger"
            class="w-100 h-100"
            :text="$t('Cancelar compra')"
            size="lg"
            @click="() => onCancel({})"
          />

          <e-button
            v-if="getIsFinishError"
            :busy="isFinishingSale"
            variant="primary"
            class="w-100 h-100"
            :text="$t('Finalizar')"
            size="lg"
            @click="() => onFinish()"
          />
        </template>
      </div>
    </div>

    <modal-atm-customer ref="modalAtmCustomer" />
  </div>
</template>

<script>
import { BBadge } from 'bootstrap-vue'
import { atmScreenEnum } from '@/store/pages/pdv/atm/atm-status'
import EButton from '@/views/components/EButton.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import { loadingOverlay } from '@/mixins'
import { getInitialAtmCatalogFilters } from '@/store/pages/pdv/atm/atm-catalog'
import ModalAtmCustomer from './modals/ModalAtmCustomer.vue'

export default {
  components: {
    EButton,
    BBadge,
    ModalAtmCustomer,
  },

  mixins: [loadingOverlay,],

  props: {},

  data: () => ({
    atmScreenEnum,
    isFinishingSale: false,
    limitExceeded: 0.0,
  }),

  computed: {
    ...mapState('pages/pdv/payBox', [
      'items',
      'paymentForm',
    ]),

    ...mapGetters('pages/pdv/atm/status', [
      'getAtmScreen',
      'getIsFinishError',
      'getIsPaymentError',
    ]),
    ...mapGetters('pages/pdv/payBox', [
      'getItems',
      'getTotalSale',
      'hasActiveTefCardPayment',
      'hasActiveTefPixPayment',
      'getCartItemsQuantity',
    ]),

    showReturn() {
      return !this.getIsFinishError
    },
    showCancel() {
      return this.getIsPaymentError || this.getIsFinishError
    },
  },

  methods: {
    ...mapActions('pages/pdv/payBox', {
      stFinishSale: 'finishSale',
      stSetClientForm: 'setClientForm',
      // stStartPayment: 'setStartPayment',
      // stSetDeliveryData: 'setDeliveryData',
      // stSetReturnForm: 'setReturnForm',
      // stAddTempDelegation: 'AddTempDelegation',
      stCancelIncompleteInvoice: 'cancelIncompleteInvoice',
      // stSetElectronicInvoiceRequired: 'setElectronicInvoiceRequired',
      stSearchAndSetClient: 'searchAndSetClient',
    }),
    ...mapActions('pages/pdv/atm', {
      stCleanAtmState: 'cleanState'
    }),
    ...mapActions('pages/pdv/atm/status', {
      stSetAtmScreen: 'setAtmScreen',
      stSetIsCartOpen: 'setIsCartOpen',
    }),
    ...mapActions('pages/pdv/atm/catalog', {
      stSetFiltersAndSearch: 'setFiltersAndSearch',
    }),

    async onBack() {
      this.stSetAtmScreen(atmScreenEnum.CATALOG)
    },

    async onShowCart() {
      this.stSetIsCartOpen(true)
    },

    async onCancel({ forceExit }) {
      try {
        let paymentErrorMessage = ''

        if (!this.isOrder) {
          if (this.hasActiveTefCardPayment) {
            paymentErrorMessage = 'PAY_BOX_SALE.CANCEL.ATM.CONFIRMATION_TEF_PAYMENT_CARD_ACTIVE'
          } else if (this.hasActiveTefPixPayment) {
            paymentErrorMessage = 'PAY_BOX_SALE.CANCEL.ATM.CONFIRMATION_TEF_PAYMENT_PIX_ACTIVE'
          }
        }

        if (paymentErrorMessage) {
          this.showAtmAlert({
            title: this.$t('Pagamentos'),
            text: this.$t(paymentErrorMessage),
            icon: 'error',
            timer: null
          })
          return
        }

        if (!forceExit) {
          let actionMsg = this.$t('Deseja realmente cancelar esta compra?')

          if (this.isBarConsumption)
            actionMsg = this.$t(
              'Deseja sair do fechamento da comanda? As alterações feitas serão perdidas.'
            )

          const confirm = await this.confirm({
            text: actionMsg,
            focusCancel: true,
          })
          if (!confirm) return

          // TODO ATM cancelamento normal?
          // switch (true) {
          //   case this.isOrder && !this.orderChanged:
          //     break
          //   default:
          //     this.delegateUserData = await this.modalAuthRef.auth(delegablePermissions.PDV_CANCEL)
          // }
        }

        this.showLoadingOverlay(this.$t('Aguarde um momento, estamos cancelando sua compra.'))

        // TODO ATM cancelamento de invoice?
        // if (this.invoiceReturn && this.invoiceReturn?.model === 'NFE') {
        //   const delegateUserData = await this.modalAuthRef.auth(
        //     delegablePermissions.ERP_INVOICE_MANAGE
        //   )
        //   this.stAddTempDelegation(delegateUserData)
        // }
        await this.stCancelIncompleteInvoice()

        await this.onCleanSale()

        this.showAtmAlert({ title: this.$t('Compra cancelada'), icon: 'success' })
      } catch (error) {
        console.error(error)
        if (error.message) {
          this.showAtmAlert({ title: 'Aviso', text: error.message, icon: 'warning' })
        } else {
          this.showAtmAlert({ error, icon: 'error' })
        }
      } finally {
        this.hideLoadingOverlay()
      }
    },

    async onFinish() {
      let validationError = { title: '', message: '' }

      // TODO ATM limite da NFE
      // const invoiceExceededValueLimits = await this.invoiceExceededLimits()

      if (this.items.length === 0) {
        validationError = {
          title: this.$t('Nenhum produto foi adicionado'),
          message: this.$t('Por favor, adicione algum e tente novamente.')
        }
      }
      else if (this.paymentForm.balance > 0) {
        validationError = {
          title: this.$t('Há recebimentos faltando'),
          message: this.$t('Por favor, registre algum pagamento em "Formas de pagamento"')
        }
      }

      // TODO ATM limite da NFE
      // else if (invoiceExceededValueLimits) {
      //   validationError.message = this.$t(
      //     'Infelizmente não será possível finalizar esta venda devido ao seu valor total. Por favor, chame um atendente para orientação.'
      //   )
      // }

      if (validationError.message) {
        await this.showAtmAlert({ title: validationError.title, text: validationError.message, icon: 'warning' })
        return
      }

      const messages = {
        confirm: { title: this.$t('Finalizar compra?'), text: ' ', icon: 'question' },
        overlay: this.$t('Finalizando compra'),
        successToast: { title: this.$t('Compra finalizada!') },
      }

      const confirm = await this.confirm(messages.confirm)

      if (!confirm) return

      const localCustomerForm = await this.$refs.modalAtmCustomer.showModal()
      await this.stSearchAndSetClient({ document: localCustomerForm?.document })

      try {
        this.isFinishingSale = true

        // if (invoiceExceededValueLimits && this.getElectronicInvoiceRequired === true) {
        //     await this.showInformation({
        //       text: this.$t(
        //         'O valor da venda ultrapassa o limite permitido para emissão do cupom fiscal.' +
        //           'Uma Nota Fiscal Eletrônica será emitida! Ao finalizar a venda, realize a impressão da DANFe gerada para o cliente!'
        //       ),
        //     })
        //     const delegateUserData = await this.modalAuthRef.auth(
        //       delegablePermissions.ERP_INVOICE_GENERATE
        //     )
        //     this.stAddTempDelegation(delegateUserData)
        //   }

        // const coldOption =
        //   this.coldOption ??
        //   (this.hasColdOptionProducts && !this.isKioskStore
        //     ? await this.$refs.modalColdOption.showModal()
        //     : null) ??
        //   this.coldOptionEnum.NATURAL

        this.showLoadingOverlay(messages.overlay)
        const resultFinishSale = await this.stFinishSale()
        await this.onCleanSale()

        await this.showAtmAlert({
          title: messages.successToast.title,
          text: resultFinishSale.displayMessage,
          icon: 'success',
          confirmButtonText: this.$t('Ok'),
        })
      } catch (error) {
        await this.showAtmAlert({
          error,
          title: 'Não foi possível concluir a compra',
          icon: 'error',
          confirmButtonText: this.$t('Voltar'),
          timer: null
        })
        const data = error.response?.data
        if (data?.Request && Array.isArray(data?.Request)) {
          this.showLoadingOverlay(this.$t('PAY_BOX_SALE.CLOSING_ALREADY_FINISHED_OVERLAY'))
          if (
            data.Request.includes('SALE_ORDER.FINISH_ERROR.SALE_ALREADY_COMPLETED_OR_CANCELED')
          ) {
            await this.onCancel({ forceExit: true })
          }
        }
      } finally {
        this.isFinishingSale = false
        this.hideLoadingOverlay()
      }
    },

    async onCleanSale() {
      await this.stCleanAtmState()
      this.stSetFiltersAndSearch(getInitialAtmCatalogFilters())
      this.limitExceeded = 0.0
    },
  },
}
</script>

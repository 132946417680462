<template>
  <atm-sidebar
    :show="showSidebar"
    :title="$t('Carrinho')"
    width="700px"
    :no-close-on-backdrop="false"
    @hidden="onHide"
  >
    <template #content>
      <div class="cart-container">
        <b-row class="cart-itens-container position-relative">
          <template v-if="getItems.length > 0">
            <b-col md="12">
              <atm-cart-item-list :cart-item-list="getItems" />
            </b-col>
            <b-col
              md="12"
              class="position-sticky "
              style="bottom: 0;"
            >
              <div class="mt-1 d-flex justify-content-end bg-white py-1 px-2 rounded">
                <p class="h3 mb-0">
                  Total: {{ getTotalSale | currency }}
                </p>
              </div>
            </b-col>
          </template>
          <b-col
            v-else
            md="12"
            class="d-flex justify-content-center align-items-center"
          >
            <p>Nenhum produto adicionado</p>
          </b-col>
        </b-row>
      </div>
    </template>

    <template #sidebar-footer>
      <div class="cart-footer h-100">
        <e-button
          variant="secondary"
          class="w-100 h-100"
          :text="$t('Continuar compra')"
          size="lg"
          @click="() => onHide()"
        />

        <e-button
          variant="primary"
          class="w-100 h-100"
          :text="$t('Pagamento')"
          size="lg"
          @click="() => onPayment()"
        />
      </div>
    </template>
  </atm-sidebar>
</template>

<script>
import { BCol, BRow } from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import { atmScreenEnum } from '@/store/pages/pdv/atm'
import EButton from '@/views/components/EButton.vue'
import AtmCartItemList from './lists/AtmCartItemList.vue'
import AtmSidebar from './sidebar/AtmSidebar.vue'

export default {
  components: {
    EButton,
    BCol,
    BRow,
    AtmSidebar,
    AtmCartItemList,
  },

  props: {},

  data: () => ({
    showSidebar: false,
  }),

  computed: {
    ...mapGetters('pages/pdv/atm/status', ['getIsCartOpen']),

    ...mapGetters('pages/pdv/payBox', [
      'getItems',
      'getTotalSale',
    ]),

    resumeData() {
      return {
        total: this.getTotalSale,
      }
    }
  },

  watch: {
    getIsCartOpen(val) {
      if (val) {
        this.showSidebar = true
      } else {
        this.showSidebar = false
      }
    }
  },

  unmounted() {
    this.stSetIsCartOpen(false)
  },

  methods: {
    ...mapActions('pages/pdv/atm/status', {
      stSetIsCartOpen: 'setIsCartOpen',
    }),

    ...mapActions('pages/pdv/atm/status', {
      stSetAtmScreen: 'setAtmScreen',
      stSetIsCartOpen: 'setIsCartOpen',
    }),

    async onPayment() {
      this.stSetAtmScreen(atmScreenEnum.PAYMENT)
      this.onHide()
    },

    onHide() {
      this.stSetIsCartOpen(false)
    }
  },
}
</script>

<style lang="scss" scoped>
.cart-container {
  // padding: 5px;
  border-radius: 0.428rem;

  // width: 98vw;
  // scrollbar-width: none;

  // animation: scroll-shadow-inset linear;
  // animation-timeline: scroll(self inline);

  .cart-itens-container {
    display: flex;
    justify-content: flex-start;
    // gap: 5px;
  }
}

.cart-footer {
  display: flex;
  gap: 20px;
}
</style>

<template>
  <b-card class="product-card mb-0 position-relative">
    <div
      v-if="showRemove"
      class="position-absolute"
      style="top: 0; right: 0;"
    >
      <e-button-icon
        icon="XIcon"
        variant="danger"
        style="padding: 12px;"
        @click="onRemoveItem"
      />
    </div>

    <b-img
      v-if="item.promotionId"
      :src="promotionImg"
      width="150"
      height="150"
      alt="Bandeira de que produto está em promoção"
      class="position-absolute"
      style="top: -15px; left: 0px; object-fit: contain;"
    />

    <div class="card-container">
      <div class="d-flex justify-content-center">
        <b-img
          :src="item.imageSquare || logoImg"
          width="150"
          height="150"
          alt="Fluid image"
          class="rounded"
          style="object-fit: contain;"
        />
      </div>

      <div>
        <p class="mb-0 h6 font-weight-bold">
          {{ item.name }}
        </p>
      </div>

      <div class="mt-auto">
        <p class="mb-0 font-weight-bold">
          {{ item.price | currency }}
        </p>
      </div>

      <div>
        <item-quantity-input
          :id="id"
          :product="item"
        />
      </div>
    </div>
  </b-card>
</template>

<script>
import EButtonIcon from '@/views/components/EButtonIcon.vue'
import { BImg, BCard } from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import logoImg from '@/assets/images/logo/echope_small.png'
import promotionImg from '@/assets/images/on_promotion.png'
import { payBoxSale } from '@/mixins'
import ItemQuantityInput from '../input/ItemQuantityInput.vue'

export default {
  components: {
    BCard,
    BImg,
    EButtonIcon,
    ItemQuantityInput,
  },

  props: {
    item: {
      type: Object,
      required: true,
    },
    id: {
      type: String,
      default: 'itemCard'
    },

    readOnly: {
      type: Boolean,
      default: false
    },
    showRemove: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      logoImg,
      promotionImg,
    }
  },

  computed: {
    ...mapGetters('pages/pdv/payBox', ['getItems', 'getDictItemsQuantity']),
  },

  methods: {
    ...mapActions('pages/pdv/atm', { stRemoveItemFromAtm: 'removeItemFromAtm', }),

    getItemCart() {
      const itemFound = this.getItems?.find(payBoxSale.methods.findProductInSaleItem(this.item))
      return itemFound
    },

    onRemoveItem() {
      const item = this.getItemCart()
      this.removeItem(item)
    },

    async removeItem(item) {
      await this.stRemoveItemFromAtm(item)
    },
  },
}
</script>

<style lang="scss" scoped>
.product-card {
  position: inherit;
  height: 350px;

  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  padding-bottom: 0;
}

.product-card:nth-last-child(1) {
  margin-left: 0px;
  margin-right: auto;
}

.card-container {
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}
</style>

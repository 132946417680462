<template>
  <div
    class="group-item"
    :class="{ 'group-item-selected': selected, 'group-item-disabled': disabled }"
    @click="$emit('click', group)"
  >
    <div class="group-img">
      <b-img
        fluid
        :src="group.image"
      />
    </div>

    <div class="group-name">
      <p
        class="mb-0"
        style="text-align: center; font-size: 0.8rem; font-weight: bold;"
      >
        {{ group.name }}
      </p>
    </div>
  </div>
</template>

<script>
import { BImg } from 'bootstrap-vue'
import logoImg from '@/assets/images/logo/echope_small.png'

export default {
  components: { BImg },
  props: {
    group: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },

  computed: {
    groupImage() {
      return this.group.image || logoImg
    }
  }
}
</script>

<style lang="scss" scoped>
.group-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 2px;
  border-radius: 5px;

  cursor: pointer;

  min-width: 100px;
  // height: 100px;

  // transition: 200ms ease-in;

  .group-name {
    word-wrap: break-word;
  }

  .group-img {
    border-radius: 10px;
    border: 1px solid var(--gray);
    // background-color: var(--white);

    img {
      border-radius: 10px;
      width: 100%;
      height: 80px;
    }
  }
}

.group-item-selected {
  color: var(--theme-color);
  border-radius: 10px;
  padding: 0px;
  // border: 5px solid var(--theme-color);
  // background-color: var(--white);

  .group-name {
    // background-color: rgba(var(--theme-color-rgb), 1);
    // color: var(--light);
    // height: 100%;
    // width: 100%;
    // padding: 4px;
    // margin-top: 5px;

    // border-radius: 5px;
    // border-top-left-radius: 0px;
    // border-top-right-radius: 0px;
  }

  &>.group-img {
    border: none;
    // background-color: var(--white);

    // estilo que inverte cor (esperado que venha png com fundo transparente e linhas escuras)
    background-color: var(--theme-color);

    img {
      filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(343deg) brightness(116%);
    }
  }
}

.group-item-disabled {
  opacity: 0.4;
}
</style>

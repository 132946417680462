<template>
  <div>
    <FormulateInput
      :id="`${id}_pId_${product.id}`"
      v-model="getItemQuantity"
      type="osk-number-with-buttons"
      :name="`${id}_pId_${product.id}`"
      @oskInput="onOskInput"
      @leftClick="onMinusClick"
      @rightClick="onPlusClick"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { debounce } from 'lodash'
import { payBoxSale } from '@/mixins'

export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
    id: {
      type: String,
      default: 'itemInput'
    },
  },

  data() {
    return {
      quantityAux: 0,
      showLocalQuantity: false,
    }
  },

  computed: {
    ...mapGetters('pages/pdv/payBox', ['getItems', 'getDictItemsQuantity']),

    getItemQuantity: {
      get() {
        let result = 0
        if (this.showLocalQuantity) {
          result = this.quantityAux
        } else {
          result = this.getQuantity()
        }

        return result
      },
      set() { }
    }
  },

  mounted() {
    this.reloadItemQuantity()
  },

  methods: {
    ...mapActions('pages/pdv/atm', {
      stAddItemFromAtm: 'addItemFromAtm',
      stRemoveItemFromAtm: 'removeItemFromAtm',
      stUpdateItemFromAtm: 'updateItemFromAtm',
    }),

    reloadItemQuantity() {
      this.quantityAux = this.getQuantity()
    },

    getItemCart() {
      const itemFound = this.getItems?.find(payBoxSale.methods.findProductInSaleItem(this.product))
      return itemFound
    },
    getQuantity() {
      // item.id pode ser kitId ou o skuId
      const saleProductKey = payBoxSale.methods.getSaleProductKey({
        kitId: (this.product.kit && this.product.id) || 0,
        promotionId: this.product.promotionId,
        skuId: (!this.product.kit && this.product.id)
      })
      const amount = this.getDictItemsQuantity[saleProductKey]
      const result = amount || 0

      if (!this.showLocalQuantity) this.quantityAux = result

      return result
    },

    // eslint-disable-next-line func-names
    onOskInput: debounce(function (val) {
      this.manageItem(val)
    }, 400),

    onMinusClick() {
      if ((this.quantityAux - 1) < 0) {
        return
      }

      this.showLocalQuantity = true
      this.quantityAux -= 1

      this.manageItem(this.quantityAux)
    },
    onPlusClick() {
      this.showLocalQuantity = true
      this.quantityAux += 1

      this.manageItem(this.quantityAux)
    },

    onRemoveItem() {
      const item = this.getItemCart()
      this.removeItem(item)
    },

    // eslint-disable-next-line func-names
    manageItem: debounce(async function (quantity) {
      try {
        const item = this.getItemCart()

        if (!item && quantity === 0)
          return

        if (item && quantity <= 0) {
          await this.removeItem(item)
          return
        }

        if (this.getQuantity() > 0) {
          await this.updateItem(item, quantity)
        } else {
          await this.addItem(quantity)
        }
      } catch (error) {
        this.showAtmAlert({ error, icon: 'warning' })
      } finally {
        this.showLocalQuantity = false
      }
    }, 300),

    async addItem(quantity) {
      await this.stAddItemFromAtm({
        amount: quantity,
        ean: this.product.ean,
        skuId: this.product.id,
      })
    },
    async removeItem(item) {
      await this.stRemoveItemFromAtm(item)
    },
    async updateItem(item, quantity) {
      await this.stUpdateItemFromAtm({
        item,
        amount: quantity
      })
    },
  },
}

</script>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "px-2 d-flex justify-content-between align-items-center w-100 h-100",
    },
    [
      _c(
        "div",
        {
          staticClass: "brand-logo mr-1 cursor-pointer",
          on: { click: _vm.onLogoClick },
        },
        [
          _c("b-img", {
            staticClass: "rounded",
            attrs: { id: "logo", src: _vm.echopeLogo, alt: "logo", fluid: "" },
          }),
        ],
        1
      ),
      _c("div", [
        _c(
          "div",
          {
            staticClass: "d-flex cursor-pointer",
            staticStyle: { gap: "20px" },
            on: { click: _vm.onShowCartSidebar },
          },
          [
            _c(
              "div",
              { staticClass: "position-relative" },
              [
                _c("b-icon", {
                  staticClass: "h2",
                  staticStyle: { color: "var(--theme-color)" },
                  attrs: { icon: "cart" },
                }),
                _vm.getCartItemsQuantity > 0
                  ? _c(
                      "b-badge",
                      {
                        staticClass: "position-absolute",
                        staticStyle: { top: "-10px", right: "-15px" },
                        attrs: { variant: "danger" },
                      },
                      [_vm._v(" " + _vm._s(_vm.getCartItemsQuantity) + " ")]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c("p", { staticClass: "mb-0 h2 text-light" }, [
              _vm._v(" " + _vm._s(_vm._f("currency")(_vm.getTotalSale)) + " "),
            ]),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
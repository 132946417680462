<template>
  <div>
    <b-sidebar
      :id="`sidebar-form-atm_${id}`"
      v-bind="$attrs"
      v-model="showLocal"
      aria-controls="sidebar-form-group"
      :aria-expanded="showLocal"
      header-class="text-center"
      :no-close-on-backdrop="noCloseOnBackdrop"
      backdrop
      shadow
      right
      @hidden="close"
    >
      <template #header="{ hide }">
        <e-sidebar-header
          :title="title"
          :hide="hide"
        />
      </template>

      <template #default>
        <e-spinner v-if="localFetching" />
        <div
          v-else
          class="px-2 py-2"
        >
          <slot name="content" />
        </div>
      </template>

      <template
        v-if="showFooter"
        #footer
      >
        <div
          class="px-2 py-1"
          style="height: 90px;"
        >
          <slot name="sidebar-footer" />
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import { BSidebar } from 'bootstrap-vue'
import { ESidebarHeader, ESpinner } from '@/views/components'

export default {
  components: {
    BSidebar,
    ESidebarHeader,
    ESpinner,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
    hideSaveButton: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
    fetching: {
      type: Boolean,
      default: false,
    },
    saving: {
      type: Boolean,
      default: false,
    },

    noCloseOnBackdrop: {
      type: Boolean,
      default: true,
    },

    /**
     * array de objetos:
     * [{
     *    variant: string,
     *    icon: string,
     *    text: string,
     *    event: string,
     * }]
     */
    extraButtons: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      showLocal: false,
      localFetching: false,
      localSaving: false,
    }
  },

  computed: {
    id() {
      // eslint-disable-next-line no-underscore-dangle
      return this._uid
    },
  },

  watch: {
    show(val) {
      this.showLocal = val
    },
    fetching(val) {
      this.localFetching = val
    },
    saving(val) {
      this.localSaving = val
    },
  },

  methods: {
    open() {
      this.showLocal = true
    },

    close() {
      this.$emit('hidden')
    },

    save() {
      this.$emit('save')
    },
  },
}
</script>

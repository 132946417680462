var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "group-item",
      class: {
        "group-item-selected": _vm.selected,
        "group-item-disabled": _vm.disabled,
      },
      on: {
        click: function ($event) {
          return _vm.$emit("click", _vm.group)
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "group-img" },
        [_c("b-img", { attrs: { fluid: "", src: _vm.group.image } })],
        1
      ),
      _c("div", { staticClass: "group-name" }, [
        _c(
          "p",
          {
            staticClass: "mb-0",
            staticStyle: {
              "text-align": "center",
              "font-size": "0.8rem",
              "font-weight": "bold",
            },
          },
          [_vm._v(" " + _vm._s(_vm.group.name) + " ")]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
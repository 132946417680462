<template>
  <div>
    <ul
      v-if="cartItemList.length > 0"
      class="atm-cart-list-container list-unstyled"
    >
      <atm-cart-item
        v-for="(item, index) in cartItemList"
        :key="`cart_item-${index}`"
        :cart-item="item"
      />
    </ul>
    <div
      v-else
      class="d-flex justify-content-center align-items-center"
    >
      <p>Nenhum produto adicionado</p>
    </div>
  </div>
</template>

<script>
import AtmCartItem from '../cards/AtmCartItem.vue'

export default {
  components: {
    AtmCartItem,
  },

  props: {
    cartItemList: {
      type: Array,
      default: () => ([])
    }
  },
}
</script>

<style lang="scss" scoped>
.atm-cart-list-container {
  &>li+li {
    margin-top: 20px;
  }
}
</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("atm-sidebar", {
    attrs: {
      show: _vm.showSidebar,
      title: _vm.$t("Carrinho"),
      width: "700px",
      "no-close-on-backdrop": false,
    },
    on: { hidden: _vm.onHide },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "cart-container" },
              [
                _c(
                  "b-row",
                  { staticClass: "cart-itens-container position-relative" },
                  [
                    _vm.getItems.length > 0
                      ? [
                          _c(
                            "b-col",
                            { attrs: { md: "12" } },
                            [
                              _c("atm-cart-item-list", {
                                attrs: { "cart-item-list": _vm.getItems },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            {
                              staticClass: "position-sticky ",
                              staticStyle: { bottom: "0" },
                              attrs: { md: "12" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "mt-1 d-flex justify-content-end bg-white py-1 px-2 rounded",
                                },
                                [
                                  _c("p", { staticClass: "h3 mb-0" }, [
                                    _vm._v(
                                      " Total: " +
                                        _vm._s(
                                          _vm._f("currency")(_vm.getTotalSale)
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ]
                      : _c(
                          "b-col",
                          {
                            staticClass:
                              "d-flex justify-content-center align-items-center",
                            attrs: { md: "12" },
                          },
                          [_c("p", [_vm._v("Nenhum produto adicionado")])]
                        ),
                  ],
                  2
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "sidebar-footer",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "cart-footer h-100" },
              [
                _c("e-button", {
                  staticClass: "w-100 h-100",
                  attrs: {
                    variant: "secondary",
                    text: _vm.$t("Continuar compra"),
                    size: "lg",
                  },
                  on: {
                    click: function () {
                      return _vm.onHide()
                    },
                  },
                }),
                _c("e-button", {
                  staticClass: "w-100 h-100",
                  attrs: {
                    variant: "primary",
                    text: _vm.$t("Pagamento"),
                    size: "lg",
                  },
                  on: {
                    click: function () {
                      return _vm.onPayment()
                    },
                  },
                }),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-sidebar",
        _vm._b(
          {
            attrs: {
              id: "sidebar-form-atm_" + _vm.id,
              "aria-controls": "sidebar-form-group",
              "aria-expanded": _vm.showLocal,
              "header-class": "text-center",
              "no-close-on-backdrop": _vm.noCloseOnBackdrop,
              backdrop: "",
              shadow: "",
              right: "",
            },
            on: { hidden: _vm.close },
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function (ref) {
                    var hide = ref.hide
                    return [
                      _c("e-sidebar-header", {
                        attrs: { title: _vm.title, hide: hide },
                      }),
                    ]
                  },
                },
                {
                  key: "default",
                  fn: function () {
                    return [
                      _vm.localFetching
                        ? _c("e-spinner")
                        : _c(
                            "div",
                            { staticClass: "px-2 py-2" },
                            [_vm._t("content")],
                            2
                          ),
                    ]
                  },
                  proxy: true,
                },
                _vm.showFooter
                  ? {
                      key: "footer",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "px-2 py-1",
                              staticStyle: { height: "90px" },
                            },
                            [_vm._t("sidebar-footer")],
                            2
                          ),
                        ]
                      },
                      proxy: true,
                    }
                  : null,
              ],
              null,
              true
            ),
            model: {
              value: _vm.showLocal,
              callback: function ($$v) {
                _vm.showLocal = $$v
              },
              expression: "showLocal",
            },
          },
          "b-sidebar",
          _vm.$attrs,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
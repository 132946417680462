var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "h-100 d-flex flex-column" },
    [
      _c("div", { staticClass: "w-100", staticStyle: { height: "90px" } }, [
        _c(
          "div",
          {
            staticClass:
              "px-2 py-1 h-100 d-flex justify-content-between align-items-center",
            staticStyle: { gap: "20px" },
          },
          [
            _vm.getAtmScreen === _vm.atmScreenEnum.CATALOG
              ? [
                  _c("e-button", {
                    staticClass: "w-100 h-100",
                    attrs: {
                      variant: "danger",
                      text: _vm.$t("Cancelar compra"),
                      size: "lg",
                    },
                    on: {
                      click: function () {
                        return _vm.onCancel({})
                      },
                    },
                  }),
                  _c("e-button", {
                    staticClass: "w-100 h-100",
                    attrs: {
                      variant: "primary",
                      text: _vm.$t("Carrinho"),
                      size: "lg",
                    },
                    on: {
                      click: function () {
                        return _vm.onShowCart()
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "right-content",
                          fn: function () {
                            return [
                              _vm.getCartItemsQuantity > 0
                                ? _c(
                                    "b-badge",
                                    { attrs: { variant: "danger" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.getCartItemsQuantity) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      1734878648
                    ),
                  }),
                ]
              : _vm._e(),
            _vm.getAtmScreen === _vm.atmScreenEnum.PAYMENT
              ? [
                  _vm.showReturn
                    ? _c("e-button", {
                        staticClass: "w-100 h-100",
                        attrs: {
                          busy: _vm.isFinishingSale,
                          variant: "secondary",
                          text: _vm.$t("Voltar"),
                          size: "lg",
                        },
                        on: {
                          click: function () {
                            return _vm.onBack()
                          },
                        },
                      })
                    : _vm._e(),
                  _vm.showCancel
                    ? _c("e-button", {
                        staticClass: "w-100 h-100",
                        attrs: {
                          variant: "danger",
                          text: _vm.$t("Cancelar compra"),
                          size: "lg",
                        },
                        on: {
                          click: function () {
                            return _vm.onCancel({})
                          },
                        },
                      })
                    : _vm._e(),
                  _vm.getIsFinishError
                    ? _c("e-button", {
                        staticClass: "w-100 h-100",
                        attrs: {
                          busy: _vm.isFinishingSale,
                          variant: "primary",
                          text: _vm.$t("Finalizar"),
                          size: "lg",
                        },
                        on: {
                          click: function () {
                            return _vm.onFinish()
                          },
                        },
                      })
                    : _vm._e(),
                ]
              : _vm._e(),
          ],
          2
        ),
      ]),
      _c("modal-atm-customer", { ref: "modalAtmCustomer" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
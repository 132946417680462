var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.cartItemList.length > 0
      ? _c(
          "ul",
          { staticClass: "atm-cart-list-container list-unstyled" },
          _vm._l(_vm.cartItemList, function (item, index) {
            return _c("atm-cart-item", {
              key: "cart_item-" + index,
              attrs: { "cart-item": item },
            })
          }),
          1
        )
      : _c(
          "div",
          { staticClass: "d-flex justify-content-center align-items-center" },
          [_c("p", [_vm._v("Nenhum produto adicionado")])]
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <section class="catalog-container">
    <template v-if="getAtmScreen === atmScreenEnum.CATALOG">
      <div class="atm-header">
        <atm-header />
      </div>

      <div
        id="atm-catalog-main-container"
        ref="scrollContainer"
        class="products-container"
        @scroll="onCatalogScroll"
      >
        <atm-catalog @scrollToTop="() => onScrollToTop('atm-catalog-main-container')" />

        <div
          v-if="!isLoadedAllCatalog && !isLoadingCatalog"
          class="px-5 w-100 flex justify-content-center"
        >
          <e-button
            class="w-100"
            text="Carregar mais produtos"
            @click="() => stFetchAtmProductCatalog()"
          />
        </div>
      </div>

      <atm-cart-sidebar />
    </template>

    <template v-if="getAtmScreen === atmScreenEnum.PAYMENT">
      <section class="payment-container">
        <atm-payment />
      </section>
    </template>

    <footer class="footer-container">
      <atm-footer />
    </footer>
  </section>
</template>

<script>
import { app, keyboardCode, loadingOverlay } from '@/mixins'
import { mapGetters, mapActions } from 'vuex'
import { atmScreenEnum } from '@/store/pages/pdv/atm/atm-status'
import EButton from '@/views/components/EButton.vue'
import AtmFooter from './components/AtmFooter.vue'
import AtmCartSidebar from './components/AtmCartSidebar.vue'
import AtmCatalog from './components/AtmCatalog.vue'
import AtmPayment from './AtmPayment.vue'
import AtmHeader from './components/AtmHeader.vue'

export default {
  components: {
    AtmHeader,
    AtmCatalog,
    AtmCartSidebar,
    AtmPayment,
    AtmFooter,
    EButton,
  },
  mixins: [app, keyboardCode, loadingOverlay],

  data() {
    return {
      unsubscribe: null,
      atmScreenEnum,
    }
  },

  computed: {
    ...mapGetters('pages/pdv/atm/status', ['getAtmScreen']),
    ...mapGetters('pages/pdv/atm/catalog', ['isLoadedAllCatalog', 'isLoadingCatalog']),
    // ...mapGetters('pages/pdv', ['needCashUp']),

    // getHeight() {
    //   // subtrai height do footer e header
    //   return this.windowSize.height - 154
    // },
  },

  watch: {
    // needCashUp(isBlocked) {
    //   if (isBlocked) {
    //     this.$refs.payBoxPurchaseInfo.purchaseInfoRemoveEvents()
    //   } else {
    //     this.$refs.payBoxPurchaseInfo.purchaseInforAddEvents()
    //   }
    // },
  },

  async mounted() {
    // try {
    //   this.showLoadingOverlay(this.$t('Preparando o ATM'))
    // } catch (error) {
    //   this.showError({ error })
    // } finally {
    //   this.hideLoadingOverlay()
    // }
  },

  created() {
    this.unsubscribe = this.$store.subscribe(async mutation => {
      if (mutation.type.startsWith('pages/pdv/payBox')) {
        await this.$store.dispatch('pages/pdv/payBox/updatePdvSession', {})
      }
      if (mutation.type.startsWith('pages/pdv/atm/status')) {
        await this.$store.dispatch('pages/pdv/atm/status/updateAtmStatus', {})
      }
    })
  },
  beforeDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe()
    }
  },

  methods: {
    ...mapActions('pages/pdv/atm/catalog', {
      stFetchAtmProductCatalog: 'fetchAtmProductCatalog'
    }),

    onCatalogScroll() {
      const container = this.$refs.scrollContainer
      const heightGapToStartFetch = 100
      if (container.scrollHeight - container.scrollTop <= container.clientHeight + heightGapToStartFetch) {
        this.stFetchAtmProductCatalog()
      }
    },

    onScrollToTop(elementId) {
      const rootEl = document.querySelector(`#${elementId}`)
      rootEl.scrollTo(0, 0)
    }
  },
}
</script>

<style scoped lang="scss">
.catalog-container {
  display: grid;
  height: 100vh;
  width: 100vw;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 110px 1fr 90px;

  .atm-header {
    grid-column: 1 / 3;
    grid-row: 1 / 2;

    background-color: #222;
  }

  // catalog screen
  .products-container {
    grid-column: 1 / 3;
    grid-row: 2 / 3;

    overflow-y: auto;
  }

  // catalog screen end

  // payment screen
  .payment-container {
    grid-column: 1 / 3;
    grid-row: 1 / 3;

    overflow-y: auto;
  }

  // payment screen end

  .footer-container {
    height: 100%;
    grid-column: 1 / 3;
    grid-row: 3 / 4;
  }
}
</style>

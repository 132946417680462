<template>
  <b-modal
    :id="getModalRefName"
    :title="$t('Informar documento')"
    no-close-on-backdrop
    no-close-on-esc
    hide-header-close
    hide-footer
    scrollable
    centered
    size="sm"
    @hidden="resetModal"
  >
    <FormulateForm
      ref="formAtmModalCustomer"
      name="formAtmModalCustomer"
      @submit="onConfirm"
    >
      <b-container>
        <b-row class="mt-1 d-flex justify-content-center mb-2">
          <b-col md="8">
            <FormulateInput
              id="atm-tab-client-document"
              v-model="localForm.document"
              :mask="['###.###.###-##', '##.###.###/####-##']"
              name="document"
              type="osk-text-mask"
              :label="$t('CPF ou CNPJ')"
              :placeholder="$t('CPF ou CNPJ')"
              validation="required|cpfCnpj"
              osk-type="numeric"
              input-mode="numeric"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col
            md="12"
            class="d-flex justify-content-end"
            style="gap: 10px;"
          >
            <e-button
              variant="outline-danger"
              :text="$t('Não desejo informar')"
              @click="onCancel"
            />
            <e-button
              variant="primary"
              :text="$t('Confirmar documento')"
              @click="onConfirm"
            />
          </b-col>
        </b-row>
      </b-container>
    </FormulateForm>
  </b-modal>
</template>

<script>
import { BModal, BContainer, BRow, BCol } from 'bootstrap-vue'
import { keyboardCode, loadingOverlay, formulateHelper, saleDomain } from '@/mixins'
import EButton from '@/views/components/EButton.vue'
import { mapActions } from 'vuex'

const defaultLocalForm = () => ({
  document: '',
})

export default {
  components: { BModal, BContainer, BRow, BCol, EButton },

  mixins: [keyboardCode, loadingOverlay, formulateHelper, saleDomain],

  props: {
    id: {
      type: String,
      default: 'default'
    }
  },

  data() {
    return {
      localForm: defaultLocalForm()
    }
  },
  computed: {
    getModalRefName() {
      return `modal_atm_customer_document-${this.id}`
    }
  },
  watch: {},
  methods: {
    ...mapActions('app/simpleKeyboard', ['hideKeyboard']),

    resetModal() {
      this.localForm = defaultLocalForm()
    },
    showModal() {
      this.$bvModal.show(this.getModalRefName)
      this.focusInput('#atm-tab-client-document')

      return new Promise(resolve => {
        this.$once('button-clicked', result => {
          this.hideModal()
          resolve(result)
        })
      })
    },
    hideModal() {
      this.hideKeyboard()
      this.resetModal()
      this.$bvModal.hide(this.getModalRefName)
    },
    async onConfirm() {
      this.$refs.formAtmModalCustomer.showErrors()
      if (!this.$refs.formAtmModalCustomer.isValid) {
        this.showInvalidDataMessage()
        return
      }

      this.$emit('button-clicked', this.localForm)
    },
    onCancel() {
      this.$emit('button-clicked', null)
    }
  },
}
</script>
